import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.a`
  text-decoration: none;
`;

const Anchor = (props) => (
  <Wrapper
    className={props.className}
    style={props.style}
    href={props.href}
    target='_blank'
    rel='noopener noreferrer'
  >
    {props.children}
  </Wrapper>
);

export default Anchor;
