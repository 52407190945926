import styled from 'styled-components';
import robotC0 from '../../assets/images/robot/robotC0.png';
import React from 'react';

const StyledWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 550px;
  height: 622px;
  background: url(${robotC0}) center center / cover;
  z-index: -1;
`;

const RobotC0 = () => {
  return (
    <StyledWrapper/>
  );
};

export default RobotC0;