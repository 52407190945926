import styled from 'styled-components';
import theme from '../../../config/theme';

const Wrapper = styled.div`
  flex: 1;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 0 16px;

  @media (min-width: ${theme.breakpoints.m}) {
    padding: 0 24px;
  }
`;

export {
	Wrapper
}
