import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

function useWindowWidth(delay = 300) {
  const [width, setWidth] = useState(null);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    const debouncedHandleResize = debounce(handleResize, delay);

    handleResize();

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    }
  }, [delay]);

  return width;
}

export default useWindowWidth;
