import styled from 'styled-components';
import robotB from '../../assets/images/robot/robotB.png';
import React from 'react';

const StyledWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 550px;
  height: 622px;
  background: url(${robotB}) center center / cover;
  transition: ${props => props.speedB} cubic-bezier(.75,0,.15,1.1);
  z-index: -2;
  transform-origin: 38% 66%;
  transform: rotate(${props => props.rotationB}deg);
`;

const RobotB = ({ speedB, rotationB, children }) => {
  return (
    <StyledWrapper speedB={speedB} rotationB={rotationB}>
      {children}
    </StyledWrapper>
  );
};

export default RobotB;