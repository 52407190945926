import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import {SECTION_IDS, SECTIONS, SEO_KEYWORDS} from '../config/constants';

import { Layout, Section, SEO } from '../components/common';

import Header from "../components/Header";
import Hero from "../components/Hero";
import SectionAbout from "../components/SectionAbout";
import SectionServices from '../components/SectionServices/SectionServices';
import SectionTechnologies from '../components/SectionTechnologies';
import SectionContact from "../components/SectionContact";
import MobileNav from "../components/MobileNav";

export const GlobalContext = React.createContext(null);

const StyledSection = styled(Section)`
  height: 1245px;
  
  @media(max-width: 1366px) {
    height: calc(1245px - 130px);
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const [currentSection, setCurrentSection] = useState(SECTION_IDS.home);
  const [isMobileMenuOpen, toggleMobileMenu] = useState(false);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.documentElement.classList.add('scroll-disabled');
    } else {
      document.documentElement.classList.remove('scroll-disabled');
    }
  }, [isMobileMenuOpen]);

  return (
    <GlobalContext.Provider value={{
      currentSection,
      setCurrentSection,
      isMobileMenuOpen,
      toggleMobileMenu
    }}>
      <MobileNav items={SECTIONS} />
      <Layout>
        <SEO keywords={SEO_KEYWORDS} />
        <StyledSection id={SECTION_IDS.home}>
          <Header siteTitle={data.site.siteMetadata.title} />
          <Hero />
        </StyledSection>
        <SectionAbout id={SECTION_IDS.about} />
        <SectionServices id={SECTION_IDS.services} />
        <SectionTechnologies id={SECTION_IDS.technologies} />
        <SectionContact id={SECTION_IDS.contact} />
      </Layout>
    </GlobalContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IndexPage;
