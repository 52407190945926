import styled from 'styled-components'
import theme from "../../config/theme";

import kukaJPEG from '../../assets/images/kuka.jpg';
import em from '../../assets/images/favicon.png';

const Wrapper = styled.section`
  position: relative;
  padding: ${theme.rhythm(2)} 0 ${theme.rhythm(4)} 0;
  overflow: hidden;
  z-index: 0;
  transition: 0.5s;
  opacity: 1;
  background: #f1f1f1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: -25%;
    width: 100%;
    height: 100%;
    background: url(${kukaJPEG}) right top no-repeat;
    background-attachment: fixed;
    background-size: cover;
    opacity: 0.25;
    z-index: -2;
    filter: url('#kuka');
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(241, 241, 241, 1) 25%, transparent);
    background-size: contain;
    z-index: -1;
  }

  @media (min-width: ${theme.breakpoints.s}) {
    padding: ${theme.rhythm(4)} 0 ${theme.rhythm(8)} 0;;
  }
`;

const TechnologyItem = styled.div`
  width: 100%;
  height: 55px;
  background: #0043b4;
  color: white;
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 2px;
  font-size: 1rem;
  transition: 0.4s;

  &:hover {
    transition-delay: -0.2s;
    background: #004ccd;
  }

  @media (min-width: ${theme.breakpoints.s}) {
    width: calc(50% - 5px);
  }

  @media (min-width: ${theme.breakpoints.m}) {
    width: calc(100% / 3 - 5px);
  }
`;

const M = styled.span`
  background: url(${em});
  width: 32px;
  height: 32px;
  filter: invert(100%);
  opacity: 0.1;
  margin-left: auto;
`;

const Technologies = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;

  @media (max-width: ${theme.breakpoints.m}) {
    justify-content: center;
  }
`;

const Subheader = styled.h2`
  font-family: JostMedium, sans-serif;
  border: none;
  margin-left: -30px;
  border-radius: 2px;
  width: 100vw;
  padding: 30px;
  background: rgba(255,255,255,0.5);
  font-size: 1.82509rem;
`

export {
	Wrapper,
	TechnologyItem,
	M,
	Technologies,
	Subheader
}
