import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { Link as ScrollLink } from 'react-scroll';

import { GlobalContext } from '../../pages';
import { SECTIONS } from '../../config/constants';

import Container from './../ui/Container';
import LogoWhite from './../common/LogoWhite';

import {
	StyledWrapper,
	HeaderMobileMenuIcon,
	HeaderNav,
	HeaderNavList,
	HeaderNavListItem
} from './Header.styles'

const Header = () => {
	const { toggleMobileMenu } = useContext(GlobalContext);

	return (
		<Container>
			<StyledWrapper id='header'>
				<Link style={{ borderRadius: 999, lineHeight: 0 }} to='/'>
					<LogoWhite />
				</Link>
				<HeaderNav>
					<HeaderNavList>
						{
							SECTIONS.map((sectionName, i) => (
								<ScrollLink
									to={sectionName.toLowerCase()}
									spy={false}
									smooth={true}
									offset={50}
									duration={500}
									key={'scrollLink' + sectionName + i}
								>
									<HeaderNavListItem key={sectionName + i}>
										{sectionName}
									</HeaderNavListItem>
								</ScrollLink>
							))
						}
					</HeaderNavList>
				</HeaderNav>
				<HeaderMobileMenuIcon isDark onClick={() => toggleMobileMenu(state => !state)} />
			</StyledWrapper>
		</Container>
	);
};

export default Header;
