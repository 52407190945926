import React from 'react';
import styled from 'styled-components';
import theme from "../../config/theme";
import {FiMenu} from "react-icons/fi";
import {slideInFromTop} from "../../utils/animations";

const StyledWrapper = styled.header`
  display: flex;
  align-items: center;
  padding: ${theme.rhythm(2)} 0;

  @media (max-width: ${theme.breakpoints.l}) {
    justify-content: space-between;
  }

  @media (max-width: ${theme.breakpoints.s}) {
    padding: ${theme.rhythm(0.5)} 0;
  }
`;

const HeaderMobileMenuIcon = styled(({ isDark, colorPrimary, colorPrimaryLight, ...rest }) => <FiMenu {...rest} />)`
  width: ${theme.rhythm(2.5)};
  height: ${theme.rhythm(2.5)};
  padding: 0.5rem;
  display: none;
  transition: all 0.2s ease;
  cursor: pointer;
  margin-right: -0.5rem;
  color: ${props => props.isDark ? '#222' : '#f5f5f5'};

  &:active, &:focus {
    color: ${props => props.isDark ? theme.colors.colorPrimaryLight : theme.colors.colorPrimary};
  }

  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
  }
`;

const HeaderNav = styled.nav`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  ${slideInFromTop('0.5s', '0s')};
`;

const HeaderNavList = styled.ul`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 ${theme.rhythm(3)} 0 0;
  list-style: none;

  @media (max-width: ${theme.breakpoints.l}) {
    margin: 0;
  }

  @media (max-width: ${theme.breakpoints.s}) {
    display: none;
  }
`;

const HeaderNavListItem = styled.li`
  position: relative;
  margin: 0;
  padding: 0.5rem 1.75rem;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 0.075rem;
  text-align: center;
  color: #101f67;
  font-family: ${theme.fontStack1};
  cursor: pointer;
  transition: 0.4s;

  &::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    transform: scaleX(0);
    transition: 0.4s;
    border-radius: 999px;
    transition-delay: -0.2s;
    background: #101f67;
  }

  &:hover {
    color: #00adff;
    transition-delay: -0.2s;

    ::before {
      transition-delay: 0s;
      transition: 0.2s;
      transform: scaleX(0.8);
      background: #00adff;
    }
  }

  &:active {
    transition: 0.05s;
    color: #7dd2ff;
  }

  @media (max-width: ${theme.breakpoints.l}) {
    justify-content: flex-end;
    font-size: 0.9rem;

    &:last-of-type {
      margin-right: -1rem;
    }
  }
`;

export {
	StyledWrapper,
	HeaderMobileMenuIcon,
	HeaderNav,
	HeaderNavList,
	HeaderNavListItem
}
