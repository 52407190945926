import { createGlobalStyle } from 'styled-components';
import theme from '../config/theme';

const GlobalStyle = createGlobalStyle`
  html {
    min-height: 100vh;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  body {
    height: 100%;
    background: #f1f1f1;
    color: #002446;

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    scrollbar-width: auto;
    scrollbar-color: #999 #f5f5f5;

    /* Chrome, Edge, and Safari */

    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-track {
      background: #f0f0f0;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #999;
      border-radius: 0;
    }
  }

  *::selection {
    background-color: #00d3ff;
    color: #001b36;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin: 0;
    padding: 0;
  }

  b {
    font-weight: 800;
    font-family: ${theme.fontStack2};
  }

  p {
    font-size: 1rem;
    color: #262f3d;

    @media (min-width: ${theme.breakpoints.m}) {
      font-size: 1.1rem;
    }
  }

  .scroll-disabled {
    overflow-y: hidden !important;
  }
`;

export default GlobalStyle;
