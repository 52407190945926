import React, { useContext } from 'react';
import { InView } from 'react-intersection-observer';
import { Link as ScrollLink } from 'react-scroll/modules';
import { FiMail } from 'react-icons/fi';

import online from '../../assets/images/online_programming.jpg';
import offline from '../../assets/images/offline_programming.jpg';
import documentation from '../../assets/images/documentation.jpg';

import theme from '../../config/theme';
import { GlobalContext } from '../../pages';
import {svgFilter} from "../common/svgFilter";

import {
  Button,
  Container,
  SectionContent
} from '../common';
import SectionTitle from '../common/Section/SectionTitle';

import {
  Wrapper,
  Tiles,
  Tile
} from './SectionServices.styles';

const SectionServices = (props) => {
  const { setCurrentSection } = useContext(GlobalContext);

  const handleIntersection = (inView) => {
    if (inView) {
      setCurrentSection(props.id);
      document.getElementById(props.id).classList.add('is-loaded');
    }
  };

  return (
    <InView onChange={handleIntersection} threshold={0.3}>
      <Wrapper id={props.id}>
        {svgFilter}
        <Container>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <SectionTitle>Services</SectionTitle>
              <SectionContent>
                <p>
                  We are specialized in automotive industry based on different
                  programming standards. We can provide virtual commisioning,
                  optimizing robot security zones, as well as creating documentation.
                  We also have an experience in conducting professional training
                  sessions in the area of industrial robot programming.
                  We are open to cooperate with integrators of industrial lines
                  or any other kind of cooperation.
                </p>
                <br />
              </SectionContent>
            </div>
            <Tiles>
              <Tile backgroundUrl={online}>
                Online programming
              </Tile>
              <Tile backgroundUrl={offline}>
                Offline programming
              </Tile>
              <Tile backgroundUrl={documentation}>
                Documenting
              </Tile>
            </Tiles>
          </div>
          <br />
          <br />
          <br />
          <h3>If you want to know more, feel free to ask.</h3>
          <ScrollLink
            to={'contact'}
            spy={false}
            smooth={true}
            offset={0}
            duration={500}
          >
            <Button style={{ marginBottom: theme.rhythm(2) }}>
              CONTACT US
              <FiMail
                style={{
                  color: theme.colors.colorPrimaryLight,
                  fontSize: '125%',
                  marginBottom: '-3px',
                  marginRight: '-7px',
                  marginLeft: '7px',
                }}
              />
            </Button>
          </ScrollLink>
        </Container>
      </Wrapper>
    </InView>
  );
};

export default SectionServices;
