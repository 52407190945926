import styled from 'styled-components';
import robotE2 from '../../assets/images/robot/robotE2.png';
import React from 'react';

function sinDegrees(angleDegrees) {
  return Math.sin(angleDegrees * Math.PI / 180);
}

const StyledWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 550px;
  height: 622px;
  background: url(${robotE2}) center center / cover;
  transition: ${props => props.speedE} cubic-bezier(.75,0,.15,1.1);
  z-index: -2;
  transform: translateX(${props => props.translationX}px) translateY(${props => props.translationY}px);
`;

const RobotE2 = ({ speedE, translationE2, children }) => {
  const translationX = (sinDegrees(69) * translationE2) / (sinDegrees(90));
  const translationY = (sinDegrees(21) * translationE2) / (sinDegrees(90));
  return (
    <StyledWrapper speedE={speedE} translationX={translationX} translationY={translationY}>
      {children}
    </StyledWrapper>
  );
};

export default RobotE2;