import React, { useContext } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { InView } from 'react-intersection-observer';
import useBreakpoint from 'use-breakpoint';

import { GlobalContext } from '../../pages';

import { Button } from '../common';

import Container from '../ui/Container'
import HeroRobot from '../HeroRobot';

import {
  Wrapper,
  HeroTitle,
  HeroSubtitle,
  HeroBackgroundFrontStyledWrapper,
  HeroBackgroundBackStyledWrapper
} from './Hero.styles';

/**
 * It is important to bind the object of breakpoints to a variable for memoization to work correctly.
 * If they are created dynamically, try using the `useMemo` hook.
 */
const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

const HeroBackgroundFront = (props) => (
  <HeroBackgroundFrontStyledWrapper id={props.id} />
);

const HeroBackgroundBack = (props) => (
  <HeroBackgroundBackStyledWrapper mousePosition={props.mousePosition} id={props.id} />
);

const Hero = () => {
  const { setCurrentSection } = useContext(GlobalContext);

  const handleIntersection = inView => {
    if (inView) {
      setCurrentSection('home');
      document.getElementById('header').classList.add('is-loaded');
    }
  };

  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');

  const isMobile = breakpoint === 'mobile';

  return (
    <>
      <HeroBackgroundBack id='heroBackground' />
      {
        !isMobile ? (
          <HeroRobot blur={'0px'} fog={'0'} scale={0.75} />
        ) : null
      }
      <HeroBackgroundFront />
      <InView onChange={handleIntersection} threshold={0.6}>
        <Container>
          <Wrapper>
            <HeroTitle>Professional<br />automation <br />engineering</HeroTitle>
            <HeroSubtitle>
              Robot programming paired with reliability
            </HeroSubtitle>
            <ScrollLink
              to={'services'}
              spy={false}
              smooth={true}
              offset={50}
              duration={500}
            >
              <Button style={{marginBottom: '10rem'}}>SEE THE SERVICES</Button>
            </ScrollLink>
          </Wrapper>
        </Container>
      </InView>
    </>
  );
};

export default Hero;
