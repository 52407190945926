import styled from 'styled-components';
import theme from '../../../config/theme';

const Wrapper = styled.button`
  outline: none;
  cursor: pointer;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(135,183,255, 0.25);
  border-radius: 2px;
  background: #001b36;
  padding: 20px 35px;
  color: #f0f0f0;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1rem;
  transition: 0.175s;

  &:hover {
    transform: translateY(-2px);
    background: #015aee;
    box-shadow: ${theme.shadows.button.hover};
  }

  &:active {
    color: white;
    transition: 0.025s;
    box-shadow: ${theme.shadows.button.default};
    transform: translateY(0px);
  }

  &:hover {
    background: #001b36;
    border-color: #01c4ff;
  }

  &:active {
    background: #015aee;
  }
`;

const ButtonLabel = styled.div`
  color: #333;
  text-transform: uppercase;
  font-size: 0.6rem;
  letter-spacing: 0.05rem;
  font-family: ${theme.fontStack1};
  align-self: center;
  margin-bottom: 5px;
`;

export {
	Wrapper,
	ButtonLabel
}
