import React, {Fragment} from 'react';

import {
	Wrapper,
	ButtonLabel
} from "./Button.styles";

const Button = (props) => {
	return (
		<Fragment>
			{
				props.label ? (
					<ButtonLabel {...props.ButtonLabelProps}>
						{props.label}
					</ButtonLabel>
				) : null
			}
			<Wrapper {...props}>
				{props.children}
			</Wrapper>
		</Fragment>
	)
}

export default Button;
