import styled from 'styled-components';
import theme from '../../../config/theme';

const Textarea = styled.textarea`
  border: 1px solid rgba(255, 255, 255, 0.75);
  background: #f0f0f0;
  font-family: ${theme.fontStack3};
  padding: 0.75rem;
  min-height: 10rem;
  resize: vertical;
  min-width: calc(66ch + 4px);
  width: 100%;
  border-radius: 2px;
  box-shadow: ${theme.shadows.input.default};
  margin-bottom: ${theme.rhythm(1)};
  transition: all 0.2s ease;
  color: #002446;
  outline: none;
  
  &:hover {
    border: #00eaff 1px solid;
    background: #c7d9ff;
  }

  &:focus {
    color: #f0f0f0;
    background: #015aee;
  }

  @media (max-width: ${theme.breakpoints.l}) {
    min-width: auto;
    width: 600px;
  }
  
  @media (max-width: ${theme.breakpoints.m}) {
    width: 100%;
    min-width: auto;
    margin: 0 0 ${theme.rhythm(2)} 0;
  }
`;

export default Textarea;
