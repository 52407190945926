import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useInView from 'react-cool-inview';

import useWindowWidth from '../../utils/hooks/useWindowWidth';

import RobotA from './RobotA';
import RobotB from './RobotB';
import RobotC from './RobotC';
import RobotC0 from './RobotC0';
import RobotD from './RobotD';
import RobotD0 from './RobotD0';
import RobotE1 from './RobotE1';
import RobotE2 from './RobotE2';

const StyledWrapper = styled.div`
  position: absolute;
  top: 223px;
  z-index: -1;
  left: 50%;
  width: 550px;
  height: 622px;
  transform-origin: bottom;
  //transform: scale(0.9);
  transform: scaleX(${props => props.flipX ? -props.scale : props.scale}) scaleY(${props => props.scale});
  filter: blur(${props => props.blur} );
  opacity: ${props => `calc(1 - ${props.fog})`};
  
  @media(max-width: 1366px) {
    top: calc(223px - 130px);
  }
`;

const MirroredRobot = styled.div`
  filter: blur(5px);
  opacity: 0.1;
  transform: scaleY(-0.5) translateY(calc(2 * -622px)) skew(${props => props.mirroredRobotSkewFactor}deg);
  transition: 1s cubic-bezier(.75,0,.15,1.1);
`;

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

// eg. range = 5 then you will get a number from -5...5
function getRandomNumber(range) {
  const randomNumber = Math.floor(Math.random() * (range + 1));
  return randomNumber * (Math.floor(Math.random() * 2) === 1 ? 1 : -1); // this will add minus sign in 50% of cases
}

const HeroRobot = ({ blur, fog, scale, flipX }) => {
  const { observe, inView } = useInView();

  // const windowWidth = useWindowWidth();

  const [animateA, setAnimateA] = useState(true);
  const [animateB, setAnimateB] = useState(true);
  const [animateC, setAnimateC] = useState(true);
  const [animateD, setAnimateD] = useState(true);
  const [animateE, setAnimateE] = useState(true);

  const [speedA, setSpeedA] = useState('1s');
  const [speedB, setSpeedB] = useState('1s');
  const [speedC, setSpeedC] = useState('1s');
  const [speedD, setSpeedD] = useState('1s');
  const [speedE, setSpeedE] = useState('1s');

  const [delayA, setDelayA] = useState(2000);
  const [delayB, setDelayB] = useState(2000);
  const [delayC, setDelayC] = useState(2000);
  const [delayD, setDelayD] = useState(2000);
  const [delayE, setDelayE] = useState(2000);

  const [rangeA, setRangeA] = useState(500);
  const [rangeB, setRangeB] = useState(5);
  const [rangeC, setRangeC] = useState(15);
  const [rangeD, setRangeD] = useState(10);
  const [rangeE, setRangeE] = useState(10);

  const [translationA, setTranslationA] = useState(0);
  const [rotationB, setRotationB] = useState(0);
  const [rotationC, setRotationC] = useState(0);
  const [rotationD, setRotationD] = useState(0);
  const [translationE1, setTranslationE1] = useState(0);
  const [translationE2, setTranslationE2] = useState(0);

  useInterval(() => {
    animateA && setTranslationA(getRandomNumber(rangeA));
  }, delayA);

  useInterval(() => {
    animateB && setRotationB(getRandomNumber(rangeB));
  }, delayB);

  useInterval(() => {
    animateC && setRotationC(getRandomNumber(rangeC));
  }, delayC);

  useInterval(() => {
    animateD && setRotationD(getRandomNumber(rangeD));
  }, delayD);

  useInterval(() => {
    const translation = Math.abs(getRandomNumber(rangeE));
    animateE && setTranslationE1(translation);
    animateE && setTranslationE2(-translation);
  }, delayE);

  // useEffect(() => {
  //   window.addEventListener('mousemove', (e) => {
  //     const offsetX = 500;
  //     setTranslationA(e.clientX - offsetX);
  //   })
  // }, []);

  // useInterval(() => {
  //   if (delay > 500) {
  //     const randomNumber = Math.floor(Math.random() * (3000 + 1));
  //     setDelay(randomNumber);
  //   }
  //   setDelay(2000);
  // }, delay);

  const mirroredRobotSkewFactor = -translationA / 25 - 15;

  return (
    <>
      {/*<div style={{display: 'flex', alignItems: 'center', position: 'fixed', bottom: 0, left: '50%', transform: 'translateX(-50%)', height: '30px', zIndex: 100000}}>*/}
      {/*  <span style={{marginRight: '5px'}}>Animate A</span>*/}
      {/*  <input style={{width: '30px', height: '30px', marginRight: '5px'}} type="checkbox" defaultChecked={false} checked={animateA} onChange={()=>setAnimateA(state=>!state)}/>*/}
      {/*  <span style={{marginRight: '5px'}}>Animate B</span>*/}
      {/*  <input style={{width: '30px', height: '30px', marginRight: '5px'}} type="checkbox" defaultChecked={false}  checked={animateB} onChange={()=>setAnimateB(state=>!state)}/>*/}
      {/*  <span style={{marginRight: '5px'}}>Animate C</span>*/}
      {/*  <input style={{width: '30px', height: '30px', marginRight: '5px'}} type="checkbox" defaultChecked={false} checked={animateC} onChange={()=>setAnimateC(state=>!state)}/>*/}
      {/*  <span style={{marginRight: '5px'}}>Animate D</span>*/}
      {/*  <input style={{width: '30px', height: '30px', marginRight: '5px'}} type="checkbox" defaultChecked={false} checked={animateD} onChange={()=>setAnimateD(state=>!state)}/>*/}
      {/*  <span style={{marginRight: '5px'}}>Animate E</span>*/}
      {/*  <input style={{width: '30px', height: '30px'}} type="checkbox" defaultChecked={false} checked={animateE} onChange={()=>setAnimateE(state=>!state)}/>*/}
      {/*</div>*/}
      <StyledWrapper ref={observe} blur={blur} fog={fog} scale={scale} flipX={flipX}>
        {
          inView ? (
            <RobotA speedA={speedA} translationA={translationA}>
              <RobotB speedB={speedB} rotationB={rotationB}>
                <RobotC speedC={speedC} rotationC={rotationC}>
                  <RobotC0/>
                  <RobotD speedD={speedD} rotationD={rotationD}>
                    <RobotD0/>
                    <RobotE1 speedE={speedE} translationE1={translationE1}/>
                    <RobotE2 speedE={speedE} translationE2={translationE2}/>
                  </RobotD>
                </RobotC>
              </RobotB>
            </RobotA>
          ) : null
        }
      </StyledWrapper>
    </>
  );
};

export default HeroRobot;

//         <MirroredRobot mirroredRobotSkewFactor={mirroredRobotSkewFactor}>
//           <RobotA speedA={speedA} translationA={translationA}>
//             <RobotB speedB={speedB} rotationB={rotationB}>
//               <RobotC speedC={speedC} rotationC={rotationC}>
//                 <RobotC0/>
//                 <RobotD speedD={speedD} rotationD={rotationD}>
//                   <RobotD0/>
//                   <RobotE1 speedE={speedE} translationE1={translationE1}/>
//                   <RobotE2 speedE={speedE} translationE2={translationE2}/>
//                 </RobotD>
//               </RobotC>
//             </RobotB>
//           </RobotA>
//         </MirroredRobot>

// import styled from 'styled-components';
// import robotPNG from '../../../assets/images/robotPNG.png';
// import React, { useEffect, useRef, useState } from 'react';
//
// const StyledWrapper = styled.div`
//   position: absolute;
//   bottom: calc(10px - ${props => props.fog * 300}px);
//   right: 25%;
//   transform: scale(${props => props.scale}) translateX(${props => props.robotTranslateValue}px);
//   width: 494px;
//   height: 667px;
//   background: url(${robotPNG}) center center / cover;
//   transition: 1s cubic-bezier(.75,0,.15,1.1);
//   filter: blur(${props => props.blur} );
//   opacity: ${props => `calc(1 - ${props.fog})`};
//   z-index: -2;
// `;
//
// function useInterval(callback, delay) {
//   const savedCallback = useRef();
//
//   useEffect(() => {
//     savedCallback.current = callback;
//   });
//
//   useEffect(() => {
//     function tick() {
//       savedCallback.current();
//     }
//
//     let id = setInterval(tick, delay);
//     return () => clearInterval(id);
//   }, [delay]);
// }
//
//
// function getRandomNumber(max){
//   const randomNumber = Math.floor(Math.random() * (max + 1));
//   return randomNumber * (Math.floor(Math.random()*2) === 1 ? 1 : -1); // this will add minus sign in 50% of cases
// }
//
// const HeroRobot = ({blur, fog, scale}) => {
//   const [robotTranslateValue, setRobotTranslateValue] = useState(100);
//   const [delay, setDelay] = useState(2000);
//
//   useInterval(() => {
//     const randomNumber = () => getRandomNumber(500);
//     setRobotTranslateValue(randomNumber);
//   }, delay);
//
//   useInterval(() => {
//     if (delay > 500) {
//       const randomNumber = Math.floor(Math.random() * (3000 + 1));
//       setDelay(randomNumber);
//     }
//     setDelay(2000);
//   }, delay);
//
//   return (
//     <StyledWrapper blur={blur} fog={fog} scale={scale} robotTranslateValue={robotTranslateValue}/>
//   )
// };
//
// export default HeroRobot;
