import React from 'react';

import {
	Wrapper
} from "./Container.styles";

const Container = (props) => {
	return (
		<Wrapper {...props}>
			{props.children}
		</Wrapper>
	)
}

export default Container;
