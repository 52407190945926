import styled from "styled-components";
import theme from "../../config/theme";
import kukaJPEG from '../../assets/images/kuka.jpg';

const Wrapper = styled.section`
  position: relative;
  padding: ${theme.rhythm(2)} 0;
  overflow: hidden;
  z-index: 0;
  transition: 0.5s;
  opacity: 1;
  background: #f1f1f1;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: -25%;
    width: 100%;
    height: 100%;
    background: url(${kukaJPEG}) right top no-repeat;
    background-attachment: fixed;

    background-size: cover;
    opacity: 0.25;
    z-index: -2;
    filter: url('#kuka');
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgb(226, 226, 226) 25%, transparent), linear-gradient(to right, rgb(240, 240, 240) 20%, transparent);
    background-size: contain;
    z-index: -1;
  }

  @media (min-width: ${theme.breakpoints.s}) {
    padding: ${theme.rhythm(4)} 0 ${theme.rhythm(8)} 0;
  }
`;

export {
	Wrapper,
}