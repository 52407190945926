import styled from 'styled-components';
import theme from '../../../config/theme';

const SectionTitle = styled.h2`
  position: relative;
  text-align: left;
  text-transform: uppercase;
  font-family: ${theme.fontStack1};
  font-weight: 400;
  font-size: 2.75rem;
  margin-bottom: ${theme.rhythm(2)};
  @media(max-width: ${theme.breakpoints.s}){
    font-size: 10.5vw;
  }
`;

export default SectionTitle;
