import React from 'react';

const svgFilter = (
	<svg style={{height: 0}}>
		<filter id='kuka'>
			<feColorMatrix
				type='matrix'
				values='
					.33 .33 .33 0 0
					.33 .33 .33 0 0
					.33 .33 .33 0 0
						0   0   0 1 0
				'
			/>
			<feComponentTransfer colorInterpolationFilters='sRGB'>
				<feFuncR type='gamma' exponent='1.5' amplitude='1.3' offset='0'/>
				<feFuncG type='gamma' exponent='1.5' amplitude='1.3' offset='0'/>
				<feFuncB type='gamma' exponent='1.5' amplitude='1.3' offset='0'/>
			</feComponentTransfer>
		</filter>
	</svg>
)

export {
	svgFilter
}
