import styled from "styled-components";
import heroBackgroundJPEG from "../../assets/images/heroBack2.jpg";
import theme from "../../config/theme";

const MobileNavFullscreenOverlay = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10vh;
  padding-left: 10vw;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: url(${heroBackgroundJPEG}) center center; //~83kb
  background-size: cover;
  z-index: 1000;
  will-change: transform;
  transition: 0.4s cubic-bezier(.75, 0, .15, 1.1);
  opacity: ${props => props.isMobileMenuOpen ? '1' : '0'};
  transform: ${props => props.isMobileMenuOpen ? 'translateX(0)' : 'translateX(100%)'};
`;

const MobileNavList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  list-style: none;
  margin: 0;
`;

const MobileNavListItem = styled.li`
  font-size: 2.5rem;
  text-align: left;
  font-family: ${theme.fontStack1};
  width: 100%;
  text-transform: uppercase;
  padding: ${theme.rhythm(0.5)} 0;
  margin: 0;
  transition: 0.2s;
  cursor: pointer;
  color: #002446;

  &:active, &:focus {
    color: ${theme.colors.colorPrimary};
  }

  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 2rem;
  }
  @media (max-width: ${theme.breakpoints.xs}) {
    font-size: 1.5rem;
  }
`;

export {
	MobileNavFullscreenOverlay,
	MobileNavList,
	MobileNavListItem
}
