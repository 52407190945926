import styled from "styled-components";
import theme from "../../config/theme";
import {slideInFromLeft} from "../../utils/animations";
import heroFront from "../../assets/images/heroFront.png";
import heroBack2 from "../../assets/images/heroBack2.jpg";

const Wrapper = styled.section`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${theme.rhythm(1)} 0;
  
  @media (max-width: ${theme.breakpoints.s}) {
    justify-content: flex-end;
    min-height: calc(100vh - 86px);
  }
  
  @media (max-width: ${theme.breakpoints.xs}) {
    padding: ${theme.rhythm(1)} 0 ${theme.rhythm(1)};
    justify-content: flex-end;
  }
  
  @media (max-width: ${theme.breakpoints.s}) and (min-height: 800px) {
    justify-content: center;
  }
`;

const HeroTitle = styled.h1`
  color: #002446;
  font-family: ${theme.fontStack1};
  font-weight: 700;
  animation-delay: 0.2s;
  letter-spacing: -0.15rem;
  margin-bottom: ${theme.rhythm(0.5)};
  will-change: transform;
  line-height: 0.9;
  ${slideInFromLeft('0.5s', '0s')};

  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 11.5vw;
    line-height: 1;
  }

  @media (max-width: ${theme.breakpoints.xs}) {
    font-size: 14vw;
    line-height: 1;
  }
`;

const HeroSubtitle = styled.h2`
  color: #2668d0;
  font-family: ${theme.fontStack3};
  font-weight: 400;
  margin-top: 1.5rem;
  font-size: 1.5rem;
  margin-bottom: ${theme.rhythm(3)};
  ${slideInFromLeft('0.5s', '0.25s')};

  @media (max-width: ${theme.breakpoints.s}) {
    margin-bottom: ${theme.rhythm(2)};
    font-size: 5.5vw;
  }

  @media (max-width: ${theme.breakpoints.s}) {
    margin-bottom: ${theme.rhythm(2)};
    font-size: 7.5vw;
  }
`;

const HeroBackgroundFrontStyledWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 150%;
  z-index: -1;
  opacity: 1;
  transition: 0.5s cubic-bezier(.75, 0, .15, 1.1);

  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 100%;
    height: 150%;
    opacity: 1;
    background: url(${heroFront}) top center no-repeat;

		@media(max-width: 1366px) {
			top: -130px;
		}
  }
`;

const HeroBackgroundBackStyledWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  opacity: 1;
  transition: 0.5s cubic-bezier(.75, 0, .15, 1.1);

  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background: url(${heroBack2}) top center no-repeat;
    z-index: 1;

		@media(max-width: 1366px) {
			top: -130px;
		}
  }

  // ::after{
  //   position: absolute;
  //   content:'';
  //   top: 0;
  //   right:0;
  //   width: 100%;
  //   height: 100%;
  //   opacity: 1;
    //   background: url(${heroFront}) top center;
  //   z-index: 3;
  // }
`;

export {
	Wrapper,
	HeroTitle,
	HeroSubtitle,
	HeroBackgroundFrontStyledWrapper,
	HeroBackgroundBackStyledWrapper
}
