import React, {useContext} from 'react';
import {InView} from 'react-intersection-observer';

import {SECTION_IDS, TECHNOLOGIES} from "../../config/constants";
import {GlobalContext} from '../../pages';

import {Container, SectionContent} from '../common';
import SectionTitle from '../common/Section/SectionTitle';
import {svgFilter} from "../common/svgFilter";

import {
	Wrapper,
	TechnologyItem,
	M,
	Technologies,
	Subheader
} from './SectionTechnologies.styles';

const SectionTechnologies = (props) => {
	const {setCurrentSection} = useContext(GlobalContext);

	const handleIntersection = (inView) => {
		if (inView) {
			setCurrentSection(props.id);
			document.getElementById(props.id).classList.add('is-loaded');
		}
	};

	return (
		<InView onChange={handleIntersection} threshold={0.3}>
			<Wrapper id={props.id}>
				{svgFilter}
				<Container>
					<SectionTitle>Technologies</SectionTitle>
					<SectionContent>
						<Subheader>
							We operate using the industrial standards and best practices.
						</Subheader>
						<p>
							We have a deep knowledge about relevant technologies, software and processes.
						</p>
					</SectionContent>
					<Technologies>
						{
							TECHNOLOGIES.slice().sort().map((technology) => (
								<TechnologyItem key={technology}>
									{technology}
									<M/>
								</TechnologyItem>
							))
						}
					</Technologies>
				</Container>
			</Wrapper>
		</InView>
	);
};

export default SectionTechnologies;
