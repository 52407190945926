import styled from 'styled-components';
import logoWhite from '../../assets/images/logo_white.png';
import { slideInFromTop } from '../../utils/animations';

const LogoWhite = styled.div`
  width: 105px;
  height: 75px;
  background: url(${logoWhite}) no-repeat center center;
  background-size: contain;
  ${slideInFromTop('0.5s', '0s')};
`;

export default LogoWhite;
