import styled from 'styled-components';
import theme from '../../../config/theme';

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 65ch;
  font-family: ${theme.fontStack3};
`;

export default SectionContent;
