import styled from 'styled-components';
import theme from '../../../config/theme';

const InputLabel = styled.label`
  font-family: ${theme.fontStack1};
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-bottom: 5px;
  margin-left: 2px;
`;

export default InputLabel;
