const fontStack1_arr = [
  'JostBold',
  'Helvetica Neue',
  'Segoe UI',
  'Helvetica',
  'Arial',
  'sans-serif',
];

const fontStack2_arr = [
  'JostSemi',
  'Helvetica Neue',
  'Segoe UI',
  'Helvetica',
  'Arial',
  'sans-serif',
];

const fontStack3_arr = [
  'JostMedium',
  'Helvetica Neue',
  'Segoe UI',
  'Helvetica',
  'Arial',
  'sans-serif',
];

const fontStack4_arr = [
  'JostBook',
  'Helvetica Neue',
  'Segoe UI',
  'Helvetica',
  'Arial',
  'sans-serif',
];

const fontStack5_arr = [
  'JostLight',
  'Helvetica Neue',
  'Segoe UI',
  'Helvetica',
  'Arial',
  'sans-serif',
];

// for usage in css (array joined to string)
const fontStack1 = fontStack1_arr.join();
const fontStack2 = fontStack2_arr.join();
const fontStack3 = fontStack3_arr.join();
const fontStack4 = fontStack4_arr.join();
const fontStack5 = fontStack5_arr.join();

// options for Typography.js
const typography = {
  baseFontSize: '16px',
  baseLineHeight: 1.55,
  scaleRatio: 4.5,
  headerFontFamily: fontStack1_arr,
  bodyFontFamily: fontStack2_arr,
};

const colors = {
  colorPrimary: `#101f67`,
  colorPrimaryLight: `#00d3ff`
};

const shadows = {
  button: {
    default: '0 4px 10px rgba(0, 0, 0, 0.35)',
    hover: '0 2px 6px rgba(0, 0, 0, 0.10), 0 4px 15px rgba(0, 0, 0, 0.15)',
  },
  input: {
    default: '0 2px 6px rgba(0, 0, 0, 0.35)',
    hover: '0 0 5px rgba(255,255,255, 1), 0 2px 6px rgba(0, 0, 0, 0.15)',
    focus: '0 0 5px rgba(255,255,255, 1), 0 0 15px white, 0 5px 15px rgba(0, 0, 0, 0.2)',
  },
};

const breakpoints = {
  xs: '400px',
  s: '600px',
  m: '900px',
  l: '1200px',
  xl: '2100px',
};

const theme = {
  fontStack1,
  fontStack2,
  fontStack3,
  fontStack4,
  fontStack5,
  typography,
  colors,
  shadows,
  breakpoints,
  rhythm: function(multiplier) {
    return `${multiplier * 16}px`;
  },
};

export default theme;
