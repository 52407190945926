import styled from 'styled-components';
import contactBackgroundJPEG from '../../assets/images/contactBg.jpg';
import theme from "../../config/theme";

const Wrapper = styled.section`
  position: relative;
  padding: ${theme.rhythm(2)} 0;
  overflow: hidden;
  z-index: 0;
  opacity: 1;
  transition: 0.5s;
  color: #f0f0f0;
  background: #0043b4 url(${contactBackgroundJPEG}) no-repeat;

  @media (min-width: ${theme.breakpoints.s}) {
    padding: ${theme.rhythm(4)} 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export {
	Wrapper,
	ContentWrapper
}
