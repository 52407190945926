import styled from 'styled-components';
import robotD from '../../assets/images/robot/robotD.png';
import React from 'react';

const StyledWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 550px;
  height: 622px;
  background: url(${robotD}) center center / cover;
  transition: ${props => props.speedD} cubic-bezier(.75,0,.15,1.1);
  z-index: -2;
  transform-origin: 22% 10%;
  transform: rotate(${props => props.rotationD}deg);
`;

const RobotD = ({ speedD, rotationD, children }) => {
  return (
    <StyledWrapper speedD={speedD} rotationD={rotationD}>
      {children}
    </StyledWrapper>
  );
};

export default RobotD;