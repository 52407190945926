import styled from 'styled-components';
import theme from '../../config/theme';
import logo from '../../assets/images/logo.png';

const Logo = styled.div`
  width: ${(props) => props.width ? props.width : theme.rhythm(4)};
  height: ${(props) => props.height ? props.height : theme.rhythm(4)};
  background: url(${logo}) no-repeat center left;
  background-size: contain;
  
  @media (max-width: ${theme.breakpoints.s}) {
    width: ${theme.rhythm(2.5)};
    height: ${theme.rhythm(2.5)};
  }
`;

export default Logo;
