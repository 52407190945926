import styled from "styled-components";

import theme from "../../config/theme";
import kukaJPEG from "../../assets/images/kuka.jpg";

const Wrapper = styled.section`
  position: relative;
  padding: ${theme.rhythm(2)} 0 ${theme.rhythm(2)} 0;
  overflow: hidden;
  z-index: 0;
  transition: 0.5s;
  opacity: 1;
  background: #f1f1f1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: -25%;
    width: 100%;
    height: 100%;
    background: url(${kukaJPEG}) right top no-repeat;
    background-attachment: fixed;
    background-size: cover;
    opacity: 0.25;
    z-index: -2;
    filter: url('#kuka');
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, rgba(241, 241, 241, 1) 25%, transparent);
    background-size: contain;
    z-index: -1;
  }
  
  @media (min-width: ${theme.breakpoints.s}) {
    padding: ${theme.rhythm(4)} 0;
  }
`;

const Tiles = styled.ul`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${theme.breakpoints.m}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Tile = styled.li`
  max-width: 350px;
  width: 100%;
  height: 350px;
  background: #0043b4;
  color: #f0f0f0;
  padding: 15px;
  border-radius: 2px;
  font-size: 2rem;
  line-height: 1.1;
  margin-bottom: 5px;
  background: url(${(props) => props.backgroundUrl});
  background-size: cover;
  background-position: 50% 50%;
  transition: 0.2s;

  @media (min-width: ${theme.breakpoints.m}) {
    margin-right: 15px;
    
    &:last-of-type {
      margin-right: 0;
    }
  }
  
  &:hover {
    filter: brightness(125%);
  }
`

export {
	Wrapper,
	Tiles,
	Tile
}
