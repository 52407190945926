import React from 'react';

import GlobalStyle from "../../styles/GlobalStyle";

import Footer from '../../components/Footer';


const Layout = (props) => (
  <>
    <GlobalStyle />
    {props.children}
    <Footer />
  </>
);

export default Layout;
