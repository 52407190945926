import styled from 'styled-components';
import theme from '../../../config/theme';

const Input = styled.input`
  border: 1px solid rgba(255, 255, 255, 0.75);
  background: #f0f0f0;
  padding: 0.75rem;
  border-radius: 2px;
  font-family: ${theme.fontStack3};
  box-shadow: ${theme.shadows.input.default};
  transition: all 0.2s ease;
  color: #002446;
  outline: none;
  
  &:hover {
    border: #00eaff 1px solid;
    background: #c7d9ff;
  }

  &:focus {
    color: #f0f0f0;
    background: #015aee;
  }

  @media (max-width: ${theme.breakpoints.m}) {
    margin: 0 0 ${theme.rhythm(1)} 0;
  }
`;

export default Input;
