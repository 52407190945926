export const FOOTER_LINK = 'www.msl-robotics.com';

export const LINKEDIN_LINK = 'https://www.linkedin.com/in/matslowik/';

export const SECTION_IDS = {
	home: 'home',
	about: 'about',
	services: 'services',
	technologies: 'technologies',
	contact: 'contact'
}

export const SECTIONS = [
	'About',
	'Services',
	'Technologies',
	'Contact'
];

export const TECHNOLOGIES = [
	'ABB',
	'KUKA',
	'Process Simulate',
	'WorkVisual',
	'RobotStudio',
	'VASS',
	'BMW LU7',
	'BMW 35up',
	'Mercedes-Benz Powertrain',
	'KRL',
	'Rapid',
	'VKRC4',
	'KRC4',
	'ABB IRC5',
	'Fanuc',
	'VFanuc',
];

export const SEO_KEYWORDS = [
	'automatics',
	'robotics',
	'online',
	'offline',
	'programming',
	'robot'
];
