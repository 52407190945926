import styled from 'styled-components';
import robotA from '../../assets/images/robot/robotA.png';
import React from 'react';

const StyledWrapper = styled.div`
  z-index: -10;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(${props => props.translationA}px);
  transition: ${props => props.speedA} cubic-bezier(.75,0,.15,1.1);
`;

const BackgroundContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 550px;
  height: 622px;
  background: url(${robotA}) center center / cover;
`;

const RobotA = ({ speedA, translationA, children }) => {
  return (
    <StyledWrapper speedA={speedA} translationA={translationA}>
      <BackgroundContainer>
        {children}
      </BackgroundContainer>
    </StyledWrapper>
  );
};

export default RobotA;