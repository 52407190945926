import styled from "styled-components";
import theme from "../../config/theme";
import patternJPEG from "../../assets/images/pattern.jpg";

const StyledWrapper = styled.footer`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0f0f0;
  background: #050505;
  padding: ${theme.rhythm(5)} 0;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${patternJPEG}) no-repeat;
    background-repeat: repeat-y;
    background-size: contain;
    opacity: 0.1;
    mix-blend-mode: hard-light;
  }

  @media (max-width: ${theme.breakpoints.m}) {
    padding: ${theme.rhythm(3)} 0;
    height: auto;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${theme.breakpoints.m}) {
    flex-direction: column;
  }
`;

const FooterCopyright = styled.p`
  font-size: 1rem;
  margin-bottom: 0;
	color: #2b2b38;
`;

const FooterMenu = styled.section`
  flex: 1;
  display: flex;
  align-items: center;
  @media (max-width: ${theme.breakpoints.m}) {
    flex-direction: column;
    margin-bottom: ${theme.rhythm(1)};
  }
`;

const FooterNav = styled.nav`
  @media (max-width: ${theme.breakpoints.m}) {
    margin-top: ${theme.rhythm(1)};
  }
`;

const FooterAddressCopyright = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;

  @media (max-width: ${theme.breakpoints.m}) {
    order: 1;
  }
`;

const FooterNavList = styled.ul`
  list-style: none;
  margin: 0 0 0 1rem;
  display: flex;

  @media (max-width: ${theme.breakpoints.m}) {
    margin-left: 0;
  }

  @media (max-width: ${theme.breakpoints.s}) {
    flex-direction: column;
  }
`;

const FooterNavListItem = styled.li`
  position: relative;
  margin: 0;
  text-align: center;
  font-size: 0.75rem;
  letter-spacing: 0.02rem;
  text-transform: uppercase;
  color: #777;
  font-family: ${theme.fontStack1};
  padding: 0.25rem 0.75rem;
  transition: 0.4s;
  cursor: pointer;

  &:hover {
    transition-delay: -0.2s;
    color: #f0f0f0;
  }

  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 1rem;
    padding: 0.25rem 1rem;
  }

  @media (max-width: ${theme.breakpoints.s}) {
    margin-bottom: 1rem;
    font-size: 0.9rem;
    padding: 0.2rem 0.3rem;
  }

  @media (max-width: ${theme.breakpoints.xs}) {
    font-size: 0.75rem;
    padding: 0.2rem 0.3rem;
  }
`;

const FooterSocialIcons = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: ${theme.breakpoints.m}) {
    flex-direction: column;
    margin-bottom: ${theme.rhythm(1)};
  }
`;

const FooterSocialIconsContent = styled.div`
  display: flex;
`;

const FooterSocialIconsLabel = styled.div`
  color: #afafaf;
  font-size: 0.75rem;
  letter-spacing: 0.02rem;
  margin-right: ${theme.rhythm(1)};

  @media (max-width: ${theme.breakpoints.m}) {
    margin-right: 0;
  }
`;

const FooterSocialIcon = styled.span`
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1.5rem;
  color: #777;
  padding: 0.4rem;
  transition: 0.4s;
  cursor: pointer;

  &:hover {
    transition-delay: -0.2s;
    color: #f0f0f0;
  }

  @media (max-width: ${theme.breakpoints.m}) {
    font-size: 2rem;
    padding: 0.6rem;
  }
`;

const FooterStyledLink = styled.a`
  text-decoration: none;
  letter-spacing: 0.075rem;
  font-size: 0.85rem;
  color: #777;

  &:hover {
    color: #f0f0f0;
  }

  &:active {
    color: #999;
  }
`;

export {
	StyledWrapper,
	FlexWrapper,
	FooterCopyright,
	FooterMenu,
	FooterNav,
	FooterAddressCopyright,
	FooterNavList,
	FooterNavListItem,
	FooterSocialIcons,
	FooterSocialIconsContent,
	FooterSocialIconsLabel,
	FooterSocialIcon,
	FooterStyledLink
}
