import React, { useContext } from 'react';
import { Link as ScrollLink } from 'react-scroll';

import { GlobalContext } from '../../pages';

import { X } from '../common';
import LogoWhite from '../common/LogoWhite';

import {
  MobileNavFullscreenOverlay,
  MobileNavList,
  MobileNavListItem
} from './MobileNav.styles';

const MobileNav = (props) => {
  const { isMobileMenuOpen, toggleMobileMenu } = useContext(GlobalContext);

  return (
    <div>
      <MobileNavFullscreenOverlay isMobileMenuOpen={isMobileMenuOpen}>
        <LogoWhite style={{ position: 'fixed', top: '10vh', left: '10vw', zIndex: 1001 }} />
        <MobileNavList>
          {props.items.map((item, i) => {
            item = item.toLowerCase();
            return (
              <ScrollLink
                key={'mobile menu nav item' + i}
                to={item}
                spy={false}
                smooth={true}
                duration={500}
              >
                <MobileNavListItem onClick={() => toggleMobileMenu(false)}>
                  {item}
                </MobileNavListItem>
              </ScrollLink>
            );
          })}
        </MobileNavList>
        <X isDark={true} onClick={() => toggleMobileMenu(false)} />
      </MobileNavFullscreenOverlay>
    </div>
  );
};

export default MobileNav;