import styled from 'styled-components';
import theme from "../../../config/theme";

const InputGroup = styled.div`
	flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 ${theme.rhythm(1)} ${theme.rhythm(1)} 0;
`;

export default InputGroup;
