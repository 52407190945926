import React from 'react';
import { Link } from 'gatsby';
import { Link as ScrollLink } from 'react-scroll';
import { FaLinkedin } from 'react-icons/fa';

import { FOOTER_LINK, LINKEDIN_LINK, SECTIONS } from '../../config/constants';

import { Anchor, Container, Logo } from '../common';

import {
  StyledWrapper,
  FlexWrapper,
  FooterCopyright,
  FooterMenu,
  FooterNav,
  FooterAddressCopyright,
  FooterNavList,
  FooterNavListItem,
  FooterSocialIcons,
  FooterSocialIconsContent,
  FooterSocialIconsLabel,
  FooterSocialIcon,
  FooterStyledLink
} from './Footer.styles';

const Footer = () => {
  return (
    <StyledWrapper>
      <Container style={{ zIndex: 0 }}>
        <FlexWrapper>
          <FooterMenu>
            <FooterNav>
              <FooterNavList>
                {
                  SECTIONS.map((sectionName, index) => (
                    <ScrollLink
                      key={sectionName + index}
                      to={sectionName.toLowerCase()}
                      spy={false}
                      smooth
                      offset={50}
                      duration={500}
                    >
                      <FooterNavListItem>{sectionName}</FooterNavListItem>
                    </ScrollLink>
                  ))
                }
              </FooterNavList>
            </FooterNav>
          </FooterMenu>
          <FooterAddressCopyright>
            <Link style={{ borderRadius: 999, lineHeight: 0 }} to='/'>
              <Logo width={'80px'}/>
            </Link>
            <FooterStyledLink href='/'>{FOOTER_LINK}</FooterStyledLink>
            <FooterCopyright>© {new Date().getFullYear()}</FooterCopyright>
          </FooterAddressCopyright>
          <FooterSocialIcons>
            <FooterSocialIconsLabel>
              LinkedIn:
            </FooterSocialIconsLabel>
            <FooterSocialIconsContent>
              <Anchor href={LINKEDIN_LINK}>
                <FooterSocialIcon>
                  <FaLinkedin />
                </FooterSocialIcon>
              </Anchor>
            </FooterSocialIconsContent>
          </FooterSocialIcons>
        </FlexWrapper>
      </Container>
    </StyledWrapper>
  );
};

export default Footer;
