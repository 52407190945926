import styled from 'styled-components';
import { FiCheckCircle } from 'react-icons/fi';

import { zoomIn } from '../../utils/animations';

const StyledFiCheckCircle = styled(FiCheckCircle)`
  ${zoomIn('0.25s')};
`;

export default StyledFiCheckCircle;
