import React, { useContext } from 'react';
import { Link as ScrollLink } from 'react-scroll/modules';
import { InView } from 'react-intersection-observer';
import { FiMail } from 'react-icons/fi';

import theme from '../../config/theme';
import { GlobalContext } from '../../pages';

import { Button, Container, SectionContent } from '../common';
import SectionTitle from '../common/Section/SectionTitle';
import {svgFilter} from "../common/svgFilter";

import {
	Wrapper
} from './SectionAbout.styles';

const SectionAbout = (props) => {
	const { setCurrentSection } = useContext(GlobalContext);

	const handleIntersection = (inView) => {
		if (inView) {
			setCurrentSection(props.id);
			document.getElementById(props.id).classList.add('is-loaded');
		}
	};

	return (
		<InView onChange={handleIntersection} threshold={0.3}>
			<Wrapper id={props.id}>
				{svgFilter}
				<Container>
					<SectionTitle>About</SectionTitle>
					<SectionContent>
						<p>
							<b>MSL-Robotics</b> is company specializing in online/offline robot programming and automatics.
							Made on the solid foundation of experience in the automotive industry, it provides the most reliable
							services of robot line automation and programming.
						</p>
						<p>
							MSL-Robotics can offer robot programming
							of various producers, such as <b>KUKA</b>, <b>ABB</b>, <b>Fanuc</b>. We can also provide
							commissioning of robot cells and plants, cycle time optimization,
							OLP programming and maintenance support after start-up.
						</p>
						<p>
							Feel free to contact us any time.
						</p>
						<ScrollLink
							to={'contact'}
							spy={false}
							smooth={true}
							offset={0}
							duration={500}
						>
							<Button>
								CONTACT US
								<FiMail
									style={{
										color: theme.colors.colorPrimaryLight,
										fontSize: '125%',
										marginBottom: '-3px',
										marginRight: '-7px',
										marginLeft: '7px',
									}}
								/>
							</Button>
						</ScrollLink>
					</SectionContent>
				</Container>
			</Wrapper>
		</InView>
	);
};

export default SectionAbout;
